import { createContext, useEffect, useReducer } from "react";
// import axios from 'axios';
import { MatxLoading } from "app/components";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import {
  actionProfile,
  actionLogin,
} from "middleware/store/actions/auth/index";
import { actionSetAccountList } from "middleware/store/actions/account/accountList";
import { actionSetBalanceList } from "middleware/store/actions/balance/balanceList";
import {
  actionDashboardTotalProfit,
  actionDashboardTotalWithdrawal,
  actionDashboardTotalDeposit,
  actionDashboardV1Daily,
  actionDashboardV1EquityBalance,
  actionDashboardV1Monthly,
  actionDashboardV1Yearly,
} from "middleware/store/actions/dashboard/dashboardVpsV1";
import { actionSetProfitList } from "middleware/store/actions/profit/profitList";
import { actionSetVpsDetail } from "middleware/store/actions/vps/vpsDetail";
import { actionSetVpsList } from "middleware/store/actions/vps/vpsList";

const initialState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false,
};

// const isValidToken = (accessToken) => {
//   if (!accessToken) return false;

//   const decodedToken = jwtDecode(accessToken);
//   const currentTime = Date.now() / 1000;
//   return decodedToken.exp > currentTime;
// };

// const setSession = (accessToken) => {
//   if (accessToken) {
//     localStorage.setItem('accessToken', accessToken);
//     axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
//   } else {
//     localStorage.removeItem('accessToken');
//     delete axios.defaults.headers.common.Authorization;
//   }
// };

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialised: true, user };
    }

    case "LOGIN": {
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
    }

    case "LOGOUT": {
      return { ...state, isAuthenticated: false, user: null };
    }

    case "REGISTER": {
      const { user } = action.payload;

      return { ...state, isAuthenticated: true, user };
    }

    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: () => {},
  logout: () => {},
  register: () => {},
});

export const AuthProvider = ({ children }) => {
  const dataDummy = useMemo(
    () => ({
      id: 1,
      role: "SA",
      name: "Jason Alexander",
      username: "jason_alexander",
      email: "jason@ui-lib.com",
      avatar: "/assets/images/face-6.jpg",
      age: 25,
    }),
    []
  );

  const dataUser = useSelector((state) => state.userProfile.userProfile);

  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async () => {
    dispatch({ type: "LOGIN", payload: { dataDummy } });
  };

  const register = async () => {
    dispatch({ type: "REGISTER", payload: { dataDummy } });
  };

  const logout = async () => {
    // kembalikan nilai null kepada seluruh isi redux
    await dispatch(actionLogin(null));
    await dispatch(actionProfile(null));
    await dispatch(actionSetAccountList(null));
    await dispatch(actionSetBalanceList(null));
    await dispatch(actionDashboardV1Daily(null));
    await dispatch(actionDashboardV1Monthly(null));
    await dispatch(actionDashboardV1Yearly(null));
    await dispatch(actionDashboardV1EquityBalance(null));
    await dispatch(actionSetProfitList());
    await dispatch(actionSetVpsDetail(null));
    await dispatch(actionSetVpsList(null));
    await dispatch(actionDashboardTotalProfit(null));
    await dispatch(actionDashboardTotalWithdrawal(null));
    await dispatch(actionDashboardTotalDeposit(null));
    await dispatch({ type: "LOGOUT" });

    console.log("logout");
  };

  useEffect(() => {
    (async () => {
      // OLD METHOD
      /* try {
        const { data } = await axios.get('/api/auth/profile');
        dispatch({ type: 'INIT', payload: { isAuthenticated: true, user: data.user } });
      } catch (err) {
        console.error(err);
        dispatch({ type: 'INIT', payload: { isAuthenticated: false, user: null } });
      } */

      // NEW METHOD
      if (
        dataUser === null ||
        Object.keys(dataUser).length === 0 ||
        dataUser === undefined
      ) {
        dispatch({
          type: "INIT",
          payload: { isAuthenticated: false, user: null },
        });
      } else {
        dispatch({
          type: "INIT",
          payload: { isAuthenticated: true, user: dataDummy },
        });
      }
    })();
  }, [dataDummy, dataUser]);

  // SHOW LOADER
  if (!state.isInitialised) return <MatxLoading />;

  return (
    <AuthContext.Provider
      value={{ ...state, method: "JWT", login, logout, register }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
