import { SET_TAKE_PROFIT, SET_TRADE } from "../../../constant/type";

const initialState = {
  tradeChart: null,
  takeProfit: null,
};

const tradeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TAKE_PROFIT:
      return {
        ...state,
        takeProfit: action.payload,
      };
    case SET_TRADE:
      return {
        ...state,
        tradeChart: action.payload,
      };
    default:
      return state;
  }
};

export default tradeReducer;
