import { SET_ACCOUNT_INFO_LIST } from "../../../constant/type";
import accountListService from "../../../services/account";
import baseService from "../../../services/index";

// const fileName = "[ACTION ACCOUNT INFO LIST] ";

/* THIS DISPATCH IS USED FOR ACCOUNT INFO LIST */
export const AccountInfoListAction = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      accountListService
        .accountInfoList(params)
        .then((res) => {
          // console.log(fileName, "Success in accountInfoListService ==> ", res);
          baseService.setHeader();
          dispatch(actionSetAccountInfoList(res));
          resolve();
        })
        .catch((err) => {
          // console.log(fileName, "Error in accountInfoListService ==> ", err);
          dispatch(actionSetAccountInfoList(null));
          reject(err);
        });
    });
  };
};

/* THIS ACTION TO REDUCERS FOR ACCOUNT LIST */
export const actionSetAccountInfoList = (payload) => {
  return {
    type: SET_ACCOUNT_INFO_LIST,
    payload: payload,
  };
};
